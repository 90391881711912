<template>
  <v-card class="overflow-hidden" color="accent">
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template>
      <b-breadcrumb
        id="row0"
        :items="breadCrumbs"
        @click="stopInterval"
      ></b-breadcrumb
    ></template>
    <v-banner single-line sticky dark>
      <v-row id="row1">
        <v-col cols="2">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar
            title="Piano Operativo - Dashboard Impatto e Avanzamento"
          ></ContextualActionBar
        ></v-col>
        <v-col cols="2" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="managePlanActivation()"
              >
                <v-icon v-if="statusPlan == 'Attiva'">mdi-flash</v-icon>
                <v-icon v-else> mdi-close-box</v-icon>
              </v-btn>
            </template>
            <span v-if="statusPlan == 'Attiva'">ATTIVA PIANO</span>
            <span v-else>CHIUDI PIANO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="uploadPlanCSV"
                ><v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>CARICAMENTO ANALISI IMPATTO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="planExport"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="exportTables"
                ><v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
            </template>
            <span>Esporta dashboard</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="goToDetail"
                ><v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>VEDI DETTAGLI</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="print"
                ><v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>STAMPA</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-row align-left"
          style="margin-top: -33px"
        >
          <v-switch
            label="PROD"
            v-model="envSwitch"
            color="secondary"
            value
            input-value="true"
            class="padd"
            v-on:change="switchAction(envSwitch)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-banner>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      color="accent"
    >
      <v-container fluid style="width: 99%">
        <perfect-scrollbar
          ><v-row style="padding: 0 10px" v-if="loaded">
            <v-col cols="4" class="pa-0"
              ><v-col class="pb-0">
                <template>
                  <v-sheet class="mx-auto" dark elevation="1">
                    <v-list dense>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, i) in impactSummary"
                          :key="i"
                        >
                          <v-list-item-icon>
                            <v-icon
                              v-text="item.icon"
                              color="secondary"
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{
                              impactSummaryValues[
                                Object.keys(impactSummaryValues)[i]
                              ]
                            }}
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-sheet>
                </template>
              </v-col></v-col
            >
            <v-col cols="8" class="pa-0">
              <v-col cols="12" class="pb-0" v-if="chartValue.length > 0">
                <ChartTrendPGActive :chart="chartValue" />
              </v-col>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-col class="pb-0">
                <SimpleTablePercentage
                  :headersNames="['D&D', '% Completamento App']"
                  :rowsValues="percCompletitionApp"
                />
              </v-col>
            </v-col>
            <v-col cols="4" class="pa-0">
              <v-col class="pb-0">
                <TableDrilldown
                  :headersNames="[
                    {
                      text: 'Priority Group',
                      value: 'priorityGroup',
                    },
                    {
                      text: '% Server',
                      value: 'serverPerc',
                      width: '8%',
                    },
                    {
                      text: '# Server',
                      value: 'serverCalc',
                      width: '8%',
                    },
                    {
                      text: '# Server to be Checked',
                      value: 'toBeChecked',
                      width: '8%',
                    },
                    {
                      text: '# Server to Start',
                      value: 'toStart',
                      width: '8%',
                    },
                  ]"
                  :rowsValues="percServers"
                  :table="'serverProgress'"
                />
              </v-col>
            </v-col>
            <v-col cols="5" class="pa-0">
              <v-col class="pb-0">
                <TableDrilldown
                  :headersNames="[
                    {
                      text: 'Priority Group',
                      value: 'priorityGroup',
                      width: '40%',
                    },
                    {
                      text: '% Applicazioni',
                      value: 'appPerc',
                      width: '8%',
                    },
                    {
                      text: '# Applicazioni',
                      value: 'appCalc',
                      width: '8%',
                    },
                    {
                      text: '% Servizi Infrastrutturali',
                      value: 'servicePerc',
                      width: '8%',
                    },
                    {
                      text: '# Servizi Infrastrutturali',
                      value: 'serviceCalc',
                      width: '8%',
                    },
                  ]"
                  :rowsValues="percAppServices"
                  :table="'applicationProgress'"
                />
              </v-col>
            </v-col>
          </v-row>
        </perfect-scrollbar>
      </v-container>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="'Vuoi chiudere il piano?'"
      v-on:confirmed="close()"
    ></ModalConfirm>
    <TextReader @load="text = $event" :disableAlert="disableAlert" />
    <ModalAlert
      @open-dialog-alert="updateDialogAlert"
      :dialogAlert="openDialogAlert"
      :alert="infoAlerts"
      :title="alertTitle"
    ></ModalAlert>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import ChartTrendPGActive from "@/components/chart/ChartTrendPGActive.vue";
import SimpleTablePercentage from "@/components/core/TableSimplePercentage.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import planIsActive from "@/mixins/planIsActive.js";
import download from "downloadjs";
import BackActionBar from "../components/core/BackActionBar.vue";
import TableDrilldown from "../components/core/TableDrilldown.vue";
import TextReader from "../components/core/TextReader.vue";
import ModalAlert from "@/components/ModalAlert.vue";

export default {
  name: "OperatingCards",
  components: {
    ContextualActionBar,
    ChartTrendPGActive,
    SimpleTablePercentage,
    ModalConfirm,
    BackActionBar,
    TableDrilldown,
    TextReader,
    ModalAlert,
  },
  mixins: [connectionMixin, planIsActive],
  data() {
    return {
      containerMaxHeight: "auto",
      items: [],
      textUpdate: "",
      planId: "",
      disableAlert: false,
      displayProgress: true,
      openDialogUpdatePlan: false,
      openDialogConfirm: false,
      interval: "",
      chartValue: [],
      envSwitch: false,
      loaded: true,
      impactSummary: [
        { text: "Servizi impattati", icon: "mdi-network" },
        { text: "Server impattati", icon: "mdi-server-network" },
        {
          text: "Tempo stimato riaccensione",
          icon: "mdi-calendar-edit",
        },
        {
          text: "Inizio impatto",
          icon: "mdi-calendar-start",
        },
        {
          text: "Tempo trascorso",
          icon: "mdi-calendar-range",
        },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Piano operativo",
          to: "/operationalplan",
        },
        {
          text: "Dashboard",
          href: "#",
        },
      ],
      impactSummaryValues: {},
      percCompletitionApp: [],
      percServers: [],
      percAppServices: [],
      numServiceToLoad: null,
      text: "",
      minTimeout: 3000,
      openDialogAlert: false,
      infoAlerts: [],
      alertTitle: "AGGIORNAMENTO ANALISI IMPATTO",
    };
  },
  mounted() {
    this.displayProgress = true;
    this.getUserProfile();

    this.containerMaxHeight = this.calcContainerMaxHeight();
    document.documentElement.style.setProperty(
      "--containerMaxHeight",
      this.containerMaxHeight
    );
    console.log("--containerMaxHeight ", this.containerMaxHeight);

    window.onafterprint = () => {
      this.containerMaxHeight = this.calcContainerMaxHeight();
      document.documentElement.style.setProperty(
        "--containerMaxHeight",
        this.containerMaxHeight
      );
      console.log("--containerMaxHeight ", this.containerMaxHeight);
    };
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),

    statusPlan: function () {
      return this.getActivation ? "Chiudi piano" : "Attiva";
    },
    updateProgress: function () {
      return this.displayProgress;
    },
  },
  watch: {
    numServiceToLoad: function () {
      if (this.numServiceToLoad === 0) {
        this.displayProgress = false;
        return false;
      }
    },
    text: function (val) {
      val.count = val.count <= this.minTimeout ? this.minTimeout : val.count;
      this.$http
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/updatePlan/" +
            this.planId,
          val,
          this.opts
        )
        .then((response) => {
          this.planId = response.data;
          this.getDashboardImpatto(this.planId, val.count);
        })
        .catch((error) => {
          console.log(error);
          this.disableAlert = true;
          alert("Si prega di ricaricare la pagina");
        });
    },
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.checkStatusPlan();
          this.getActivePlan();
          if (this.$route.params.planId != undefined) {
            this.planId = this.$route.params.planId;
            this.getDashboardValue(this.$route.params.planId);
            this.refreshDashboard();
          } else {
            alert("Si prega di ricaricare la pagina");
          }
        }
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async waitTimeout(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    async getDashboardImpatto(id, timeout) {
      var bodyData = {
        onlyProd: false,
      };
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_DASHBOARD_IMPATTO") +
            "/" +
            id,
          bodyData,
          this.opts
        );
        if (response) {
          if (!response.data.completed) {
            await this.waitTimeout(timeout);
            this.getDashboardImpatto(id, timeout);
          } else {
            if (response.data.report.message) {
              this.infoAlerts = [];
              this.infoAlerts.push(response.data.report.message);
              this.updateDialogAlert(true);
            }
            // this.activate(false);
            localStorage.setItem("dataPlan", JSON.stringify(response.data));
            // localStorage.setItem("planIsActive", false);
            this.disableAlert = true;
            this.getDashboardValue(this.planId);
          }
        }
      } catch (error) {
        console.log(error);
        this.disableAlert = true;
        alert("Si prega di ricaricare la pagina");
      }
    },
    updateDialogAlert(value) {
      this.openDialogAlert = value;
    },
    calcContainerMaxHeight() {
      const el0 = document.getElementById("row0");
      const el1 = document.getElementById("row1");

      const height = el0.offsetHeight + el1.offsetHeight + 100;
      return height > 280 ? `calc(100vh - ${height}px)` : "calc(100vh - 280px)";
    },
    async print() {
      this.containerMaxHeight = "auto";
      document.documentElement.style.setProperty(
        "--containerMaxHeight",
        this.containerMaxHeight
      );
      console.log("--containerMaxHeight ", this.containerMaxHeight);
      window.print();
    },
    managePlanActivation() {
      if (!this.getActivation && this.activeplan.length > 0) {
        alert(
          "Esiste già un piano attivo. Chiuderlo prima di attivarne un altro"
        );
      } else {
        this.updateDialogConfirm(true);
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    close() {
      this.closePlan(this.planId);
    },
    async getDashboardValue(planId) {
      this.displayProgress = true;
      this.loaded = false;
      var bodyData = {
        onlyProd: this.envSwitch,
      };
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_DASHBOARD") +
            "/" +
            planId,
          bodyData,
          this.opts
        );
        localStorage.setItem("summary", JSON.stringify(response.data.summary));
        this.impactSummaryValues = response.data.summary;
        this.percCompletitionApp = response.data.completion;
        this.percServers = response.data.servers;
        this.percAppServices = response.data.appServices;
        this.chartValue = JSON.parse(JSON.stringify(response.data.chart));
        this.loaded = true;
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
      }
    },

    async activePlan(planId) {
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_ACTIVE_PLAN") +
            "/" +
            planId,
          null,
          this.opts
        );
        console.log(response);
        this.activate(true);
        localStorage.setItem("planIsActive", true);
        this.displayProgress = false;
      } catch (error) {
        this.activate(false);
        this.displayProgress = false;
        if (error.response.status == 409) {
          alert(
            "Il piano è stato già chiuso, non può essere attivato nuovamente"
          );
        } else {
          alert("Si prega di ricaricare la pagina");
        }
      }
    },
    async closePlan(idPlan) {
      this.updateDialogConfirm(false);
      this.displayProgress = true;

      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_CLOSE_PLAN") +
            "/" +
            idPlan,
          null,
          this.opts
        );
        console.log(response);
        this.displayProgress = false;
        localStorage.setItem("planIsActive", false);
        this.stopInterval();
        this.$router.push("/operationalplan");
      } catch (error) {
        this.activate(true);
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    uploadPlanCSV() {
      this.disableAlert = false;
      document.getElementById("text-reader").value = "";
      document.getElementById("text-reader").click();
    },
    async exportTables() {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_PLAN") +
            "-dashboard/" +
            this.planId,
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        console.log(response);
        download(response.data, "dashboard-piano.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    async planExport() {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_PLAN") +
            "/" +
            this.planId,
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        console.log(response);
        download(response.data, "export-piano.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    checkStatusPlan() {
      let isActive = localStorage.getItem("planIsActive");
      if (isActive == "false") {
        this.activate(false);
      } else {
        this.activate(true);
      }
    },
    goback() {
      this.stopInterval();
      this.$router.push("/operationalplan");
    },
    goToDetail() {
      this.stopInterval();
      let routeData = this.$router.resolve({
        name: "OpPlanFailoverGroupsId",
        params: {
          planId: this.$route.params.planId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    refreshDashboard() {
      this.interval = setInterval(() => {
        this.getDashboardValue(this.planId);
      }, 180000);
      setTimeout(() => {
        this.stopInterval();
        /*  alert("Premere ok per visualizzare i dati aggiornati");
        location.reload(); */
      }, 1700000);
    },
    stopInterval() {
      clearInterval(this.interval);
    },
    switchAction(value) {
      this.envSwitch = value;
      this.getDashboardValue(this.$route.params.planId);
    },
  },
};
</script>

<style scoped>
:root {
  --containerMaxHeight: calc(100vh - 250px);
}
@media print {
  #scrolling-techniques-7,
  .container,
  .ps,
  .row {
    height: auto !important;
    max-height: auto !important;
  }
}
#scrolling-techniques-7,
.ps {
  height: var(--containerMaxHeight);
  max-height: var(--containerMaxHeight);
}
.ps {
  width: 100%;
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}
.mx-auto {
  padding: 3% !important;
  height: 350px !important;
}
.padd {
  padding-left: 15px;
}
</style>
