<template>
  <!-- The Simple Table is created by default with 2 column headings and 2 columns. 
  The first column always has an indent on the left, while the others are centered -->
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr class="header">
          <th
            v-for="(name, index) in headersNames"
            :key="index"
            :class="index === 0 ? 'text-left' : 'text-center'"
            scope="col"
          >
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(values, idx) in rowsValues">
          <tr :key="idx">
            <template v-for="(value, key, index) in values">
              <td v-if="index === 0" :key="index" class="text-left">
                {{ value }}
              </td>
              <td v-if="index === 1" :key="index" class="text-center">
                <span v-if="value.num != 0 || value.tot != 0">
                  {{ value.num }}/{{ value.tot }} ({{
                    calculatePercentage(value.num, value.tot)
                  }}%)</span
                >
                <span v-if="value.num == 0 && value.tot == 0">0</span>
              </td>
              <td v-if="index === 2" :key="index" class="text-center">
                <span v-if="value.num != 0 || value.tot != 0">
                  {{ value.num }}/{{ value.tot }} ({{
                    calculatePercentage(value.num, value.tot)
                  }}%)</span
                >
                <span v-if="value.num == 0 && value.tot == 0">0</span>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableSimplePercentage",
  props: {
    headersNames: {
      type: Array,
      default: function () {
        return ["Column 1", "Column 2", "Column 3"];
      },
    },
    rowsValues: {
      type: Array,
      default: function () {
        return [
          {
            priorityGroup: "Servizi A",
            services: { num: 10, tot: 30 },
            app: { num: 1, tot: 3 },
          },
          {
            priorityGroup: "Servizi B",
            services: { num: 20, tot: 40 },
            app: { num: 2, tot: 4 },
          },
        ];
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    calculatePercentage(num, tot) {
      return Math.round(100 * (num / tot));
    },
  },
};
</script>

<style>
td {
  font-size: 10px !important ;
}
tr {
  max-height: 10px !important;
}
tr.header {
  background-color: #949494;
}
</style>
