<template>
  <v-sheet elevation="1">
    <v-chart
      class="chart"
      :option="option"
      theme="enitheme"
      :manual-update="false"
      autoresize
      :loading="barLoading"
      :loadingOptions="barLoadingOptions"
    />
  </v-sheet>
</template>

<script>
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import theme from "@/assets/enitheme.json";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
]);
// registering custom theme
registerTheme("enitheme", theme);

export default {
  name: "ChartAppImpact",
  components: {
    VChart,
  },
  props: {
    chart: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      barLoading: false,
      barLoadingOptions: {
        text: "Caricamento…",
        textColor: "#fff",
        color: "#fdd300",
        maskColor: "rgba(0, 0, 0, 0.7)",
      },

      option: {
        title: {
          text: "Andamento per Priority Group",
          top: 10,
          left: "center",
          textStyle: { fontWeight: "normal", fontSize: "12" },
        },
        tooltip: {},
        legend: {
          data: [
            "Planned",
            "Infra Service Ok",
            "Re-work",
            "in Pipeline",
            "Business Service Ok",
            "AM Check",
          ],
          top: 50,
          right: "20",
        },
        grid: {
          top: "25%",
          left: "3%",
          right: "4%",
          bottom: "1%",
          containLabel: true,
        },
        dataset: {
          dimensions: [
            "priorityGroup",
            "planned",
            "infraServiceOk",
            "inPipeline",
            "rework",
            "businessServiceOk",
            "amCheck",
          ],
          source: [],
        },
        xAxis: {
          type: "category",
          axisLabel: { rotate: 30, interval: 0, fontSize: 8 },
          yAxisIndex: 2,
        },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            name: "Planned",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "Infra Service Ok",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "in Pipeline",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "Re-work",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "Business Service Ok",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "AM Check",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getOption();
    }, 500);
  },

  methods: {
    getOption() {
      this.option.dataset.source = this.chart;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>
