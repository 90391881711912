<template id="tableDrilldownwDetail">
  <v-data-table
    dense
    :headers="headersNames"
    :items="setvalue(rowsValues.drillDown)"
    :items-per-page="-1"
    :hide-default-footer="true"
    :hide-default-header="true"
    @click:row="openDetail"
  >
    <template v-slot:[`item.failoverGroupName`]="{ item }">
      <td style="word-break: break-all" :style="getHeaderWidth()">
        {{ item.failoverGroupName }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "TableDrilldownDetail",
  props: {
    headersNames: {
      type: Array,
    },
    rowsValues: {
      type: Object,
      default: function () {
        return [{}];
      },
    },
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
    };
  },
  methods: {
    getHeaderWidth() {
      const headerWidth = this.headersNames.find(
        (h) => h.value === "failoverGroupName"
      ).width;
      if (!headerWidth) {
        return "";
      } else {
        return `width: ${headerWidth}`;
      }
    },
    openDetail(value) {
      console.log(value);
      this.$router.push({
        name: "OpPlanFailoverGroupsDetails",
        params: {
          planId: this.$route.params.planId,
          failoverGroupId: value.failoverGroupId,
        },
      });
    },
    calculatePercentage(num, tot) {
      if (num == 0 && tot == 0) {
        return 0;
      } else {
        return Math.round(100 * (num / tot));
      }
    },
    setvalue(items) {
      items.forEach((value) => {
        if (value.server) {
          value.serverPerc =
            this.calculatePercentage(value.server.num, value.server.tot) + "%";

          value.serverCalc =
            value.server.num.toString() + "/" + value.server.tot.toString();
        }
        if (value.app) {
          value.appPerc =
            this.calculatePercentage(value.app.num, value.app.tot) + "%";

          value.appCalc =
            value.app.num.toString() + "/" + value.app.tot.toString();
        }
        if (value.service) {
          value.servicePerc =
            this.calculatePercentage(value.service.num, value.service.tot) +
            "%";

          value.serviceCalc =
            value.service.num.toString() + "/" + value.service.tot.toString();
        }
      });

      return items;
    },
  },
};
</script>

<style scoped></style>
