<template id="tableDrilldownw">
  <v-data-table
    dense
    :headers="headersNames"
    :items="setvalue(rowsValues)"
    :expanded="expanded"
    item-key="priorityGroup"
    :items-per-page="-1"
    show-expand
    :hide-default-footer="false"
    class="elevation-1"
  >
    <template> </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="px-2">
        <TableDrilldownDetail
          :headersNames="setTableDrilldownDetailHeader()"
          :rowsValues="item"
        >
        </TableDrilldownDetail>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import TableDrilldownDetail from "../core/TableDrilldownDetail.vue";
export default {
  name: "TableDrilldown",
  components: { TableDrilldownDetail },
  props: {
    headersNames: {
      type: Array,
    },
    rowsValues: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
    table: {
      default: "",
      String,
    },
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
    };
  },
  methods: {
    calculatePercentage(num, tot) {
      if (num == 0 && tot == 0) {
        return 0;
      } else {
        return Math.round(100 * (num / tot));
      }
    },
    setvalue(items) {
      items.forEach((value) => {
        if (value.server) {
          value.serverPerc =
            this.calculatePercentage(value.server.num, value.server.tot) + "%";

          value.serverCalc =
            value.server.num.toString() + "/" + value.server.tot.toString();
        }
        if (value.app) {
          value.appPerc =
            this.calculatePercentage(value.app.num, value.app.tot) + "%";

          value.appCalc =
            value.app.num.toString() + "/" + value.app.tot.toString();
        }
        if (value.service) {
          value.servicePerc =
            this.calculatePercentage(value.service.num, value.service.tot) +
            "%";

          value.serviceCalc =
            value.service.num.toString() + "/" + value.service.tot.toString();
        }
      });

      return items;
    },
    setTableDrilldownDetailHeader() {
      let header = [];
      if (this.table == "server") {
        header = [
          { text: "Failover Group", value: "failoverGroupName", width: "20%" },
          { text: "# Server Prod", value: "prod", width: "20%" },
          { text: "# Server NO Prod", value: "noProd", width: "20%" },
          { text: "# Server to Start", value: "toStart", width: "20%" },
          {
            text: "# Server to be Checked",
            value: "toBeChecked",
            width: "20%",
          },
        ];
      }
      if (this.table == "application") {
        header = [
          { text: "Failover Group", value: "failoverGroupName", width: "50%" },
          { text: "Applicazioni", value: "applications", width: "20%" },
          {
            text: "Servizi Infrastrutturali",
            value: "servicesInfra",
            width: "20%",
          },
        ];
      }
      if (this.table == "serverProgress") {
        header = [
          { text: "Failover Group", value: "failoverGroupName", width: "40%" },
          {
            text: "% Server",
            value: "serverPerc",
            width: "8%",
            align: "left",
          },
          {
            text: "# Server",
            value: "serverCalc",
            width: "8%",
            align: "left",
          },
          {
            text: "# Server to be Checked",
            value: "toBeChecked",
            width: "8%",
            align: "left",
          },
          {
            text: "# Server to Start",
            value: "toStart",
            width: "8%",
            align: "left",
          },
        ];
      }
      if (this.table == "applicationProgress") {
        header = [
          { text: "Failover Group", value: "failoverGroupName", width: "40%" },
          {
            text: "Applicazioni",
            value: "appPerc",
            width: "8%",
            align: "left",
          },
          {
            text: "Applicazioni",
            value: "appCalc",
            width: "8%",
            align: "left",
          },
          {
            text: "Servizi Infrastrutturali",
            value: "servicePerc",
            width: "8%",
            align: "left",
          },
          {
            text: "Servizi Infrastrutturali",
            value: "serviceCalc",
            width: "8%",
            align: "left",
          },
        ];
      }
      return header;
    },
  },
};
</script>

<style></style>
